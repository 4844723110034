
import Vue from 'vue';

import DonationTable from './DonationTable.vue';

export default Vue.extend({
    name: 'Donation',

    components: { DonationTable },
});
